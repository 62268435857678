import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import BinahMonitor from './BinahMonitor';
import SettingsBars from './SettingsBars';
import { Flex } from './shared/Flex';
import { useCameras, useDisableZoom } from '../hooks';
import UAParser from 'ua-parser-js';
import '../style/default.scss';


const Container = styled(Flex) <{ isSettingsOpen: boolean }>`
  height: 100%;
  width: 100%;
  position: relative;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: ${({ isSettingsOpen }) =>
    isSettingsOpen && 'rgba(0, 0, 0, 0.5)'};
`;

const App = () => {

  const cameras = useCameras();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [cameraId, setCameraId] = useState<string>();
  const [isLicenseValid, setIsLicenseValid] = useState(false);
  const [isMobile] = useState(
    UAParser(navigator.userAgent).device.type === 'mobile',
  );
  useDisableZoom();

  const onSettingsClickedHandler = useCallback((event) => {
    const settingsBars = document.getElementById('settingsBars');
    const isSettingsButtonClicked = event.target.id === 'settingsButton';

    const isInsideSettingsClicked =
      settingsBars.contains(event.target as Node) || isSettingsButtonClicked;

    if (!isInsideSettingsClicked) {
      setIsSettingsOpen(false);
    }
  }, []);

  useEffect(() => {
    document.title = "Facial Scan";
    document.addEventListener('click', onSettingsClickedHandler);
    return () => {
      document.removeEventListener('click', onSettingsClickedHandler);
    };

  }, []);

  const updateLicenseStatus = useCallback((valid) => {
    setIsLicenseValid(valid);
  }, []);

  const toggleSettingsClick = useCallback(() => {
    setIsSettingsOpen(!isSettingsOpen);
  }, [isSettingsOpen]);

  const handleCloseSettings = useCallback(({ cameraId }) => {
    setCameraId(cameraId);
    setIsSettingsOpen(false);
  }, []);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    // Get the value of the 'param' query parameter
    const paramValue = urlParams.get('param');
    console.log('Received parameter:', paramValue);
    localStorage.setItem('key', paramValue)
    // Get the value of the 'message' query parameter
    const messageValue = urlParams.get('message');
    console.log('Received message:', messageValue);
    localStorage.setItem('auth', messageValue)
  }, []);

  // useEffect(() => {
  //   //const urlParams = new URLSearchParams(window.location.search);
  //   //const paramValue = urlParams.get('param');
  //   // console.log('Received data:',  window);
  //  // Extract the query parameters from the URL
  //   const queryString = window.location.search;
  //   const urlParams = new URLSearchParams(queryString);

  //   // Get the value of the 'param' query parameter
  //   const paramValue = urlParams.get('param');
  //   console.log('Received parameter:', paramValue);

  //   // Get the value of the 'message' query parameter
  //   const messageValue = urlParams.get('message');
  //   console.log('Received message:', messageValue);
  //   localStorage.setItem('auth', messageValue)
  //   // window.addEventListener('message', function(event) {
  //   //   console.log('Received data:', event);
  //   //   // Verify that the message is coming from an allowed origin
  //   //   if (event.origin === `https://192.168.1.10:8001`) {
  //   //     // Extract data from the message
  //   //     const dataReceived = event.data;

  //   //     // Do something with the received data
  //   //     console.log('Received data:', dataReceived);
  //   //     console.log(localStorage.setItem('auth', dataReceived))
  //   //   }
  //   // });
  //   // const fetchData = async () => {
  //   //   try {
  //   //     const response = await GetPatientDataService.getPatientList(paramValue);
  //   //     console.log('Patient list:', response);
  //   //   } catch (error) {
  //   //     console.error('Error while fetching patient list:', error);
  //   //   }
  //   // };

  //   // fetchData();
  // }, []);
  useEffect(() => {
    if (!cameras?.length) return;
    setCameraId(cameras[0].deviceId);
  }, [cameras]);
  // console.log("loaded")
  // const urlParams = new URLSearchParams(window.location.search);
  // const paramValue = urlParams.get('param');
  // console.log(paramValue); 
  // function test() {

  //   const queryString = window.location.search;
  //   const urlParams = new URLSearchParams(queryString);

  //   // Get the value of the 'param' query parameter
  //   const paramValue = urlParams.get('param');
  //   console.log('Received parameter:', paramValue);

  //   // Get the value of the 'message' query parameter
  //   const messageValue = urlParams.get('message');
  //   console.log('Received message:', messageValue);
  //   localStorage.setItem('auth', messageValue)
  //   // window.addEventListener('message', function(event) {
  //   //   console.log('Received data:', event);
  //   //   // Verify that the message is coming from an allowed origin
  //   //   if (event.origin === `https://192.168.1.10:8001`) {
  //   //     // Extract data from the message
  //   //     const dataReceived = event.data;

  //   //     // Do something with the received data
  //   //     console.log('Received data:', dataReceived);
  //   //     console.log(localStorage.setItem('auth', dataReceived))
  //   //   }
  //   // });
  //   const fetchData = async () => {
  //     try {
  //       const response = await GetPatientDataService.getPatientList(paramValue);
  //       console.log('Patient list:', response);
  //     } catch (error) {
  //       console.error('Error while fetching patient list:', error);
  //     }
  //   };



  //   fetchData();
  // }
  return (
    <Container isSettingsOpen={isSettingsOpen}>
      <BinahMonitor
        showMonitor={!(isMobile && isSettingsOpen)}
        cameraId={cameraId}
        onLicenseStatus={updateLicenseStatus}
        onSettingsClick={toggleSettingsClick}
        isSettingsOpen={isSettingsOpen}
      />
      <SettingsBars
        open={isSettingsOpen}
        onClose={handleCloseSettings}
        cameras={cameras}
        isLicenseValid={isLicenseValid}
      />
    </Container>
  );

};

export default App;
