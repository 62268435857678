import axios, { AxiosResponse, AxiosError } from 'axios';
import instance from './axiosinstance';
import {BASEURL} from './enviornment';

console.log(BASEURL);

export const getLicenseKey = () =>{
  return instance.get(BASEURL+`/api/v1/consumer/validate/${localStorage.getItem('key')}`,{})
  .then((response:AxiosResponse)=>{
    return response
  })
}

export const saveScanData = (payload: any) =>{
    return instance.post(BASEURL+`/api/v1/consumer/simpl-data/facial-scan?simplId=${localStorage.getItem('simpl_id')}`,payload)
    .then((response:AxiosResponse)=>{
      return response
    })
  }