import React,{ useEffect, useState } from 'react';
import styled from 'styled-components';
import { Flex } from '../shared/Flex';
const Wrapper = styled(Flex)`
  position: absolute;
  left: 0;
  top: 10%;
  height: 70px;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(17, 17, 17 , 6.0);
`;

const Message = styled.div`
  padding: 5px;
  font-size: 14px;
  color: white;
  text-align: center;
`;

const ApiSuccessAlert = ({ message }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (message) {
      setVisible(true);
      const timeout = setTimeout(() => {
        setVisible(false);
      }, 2000); // Set timeout to hide the message after 2 seconds
      return () => clearTimeout(timeout); // Clear the timeout on component unmount
    }
  }, [message]);

  if (!visible) {
    return null;
  }

  if (!message) {
    return null;
  }

  return (
    <Wrapper>
      <Message className='suceesmsg'>{message}</Message>
    </Wrapper>
  );
};
export default ApiSuccessAlert;
