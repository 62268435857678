import axios from 'axios';

const instance = axios.create();
instance.interceptors.request.use(config => {
  
  const token = localStorage.getItem('auth');
  if (token) {
    config.headers['accept'] = '*/*'
    config.headers['Content-Type'] = 'application/json'
    config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('auth')
    config.headers['x-simpl-locationid'] = localStorage.getItem('location')
  }
  return config
},
error => {
  Promise.reject(error)
});
 
export default instance;