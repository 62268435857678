import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import monitor, {
  AlertData,
  DeviceOrientation,
  EnabledVitalSigns,
  FaceSessionOptions,
  HealthMonitorCodes,
  HealthMonitorSession,
  ImageValidity,
  OfflineMeasurements,
  SessionState,
  VitalSigns,
  VitalSignsResults,
} from '@binah/web-sdk';
import { InfoType, InfoData } from '../types';
import { saveScanData } from '../components/common-service';
import Spinner from '../components/Spinner';
const useMonitor = (
  video: MutableRefObject<HTMLVideoElement>,
  cameraId: string,
  processingTime: number,
  licenseKey: string,
  productId: string,
  startMeasuring: boolean,
  isApierror:boolean,
) => {
  const [session, setSession] = useState<HealthMonitorSession>();
  const [sessionState, setSessionState] = useState<SessionState>();
  const [isMonitorReady, setIsMonitorReady] = useState<boolean>();
  const [isCameraOn, setCameraOn] = useState<boolean>(false);
  const [enabledVitalSigns, setEnabledVitalSigns] = useState<
    EnabledVitalSigns
  >();
  const [offlineMeasurements, setOfflineMeasurements] = useState<
    OfflineMeasurements
  >();
  const [vitalSigns, setVitalSigns] = useState<VitalSigns | null>();

  const [error, setError] = useState<AlertData>({ code: -1 });
  const [warning, setWarning] = useState<AlertData>({ code: -1 });
  const [info, setInfo] = useState<InfoData>({ type: InfoType.NONE });
  const [facialstop, setfacialstop] = useState<any>();
  const [apierrormsg, setapierrormsg] = useState<any>();
  const [apisuccessmsg, setapisuccessmsg] = useState<any>();
  const isDismissing = useRef<boolean>(false);

  const setInfoWithDismiss = useCallback(
    (info: InfoData, seconds?: number) => {
      if (!isDismissing.current) {
        setInfo(info);
        if (seconds) {
          isDismissing.current = true;
          setTimeout(() => {
            setInfo({ type: InfoType.NONE });
            isDismissing.current = false;
          }, seconds * 1000);
        }
      }
    },
    [InfoType, setInfo, info, isDismissing, isDismissing.current],
  );

  useEffect(()=>{
  if(isApierror){
    setCameraOn(true);
  }
  },[isApierror])

  useEffect(()=>{
    setCameraOn(false);

    setInfo({
      message: `Warning`,
      type: InfoType.INSTRUCTION,
    });
  },[])

  const updateVitalSigns = useCallback((vitalSigns) => {
    setVitalSigns((prev) => ({
      ...prev,
      ...vitalSigns,
    }));
  }, []);

  const onVitalSign = useCallback((vitalSign: VitalSigns) => {
    updateVitalSigns(vitalSign);
  }, []);

  const onFinalResults = useCallback((vitalSignsResults: VitalSignsResults) => {
    updateVitalSigns(vitalSignsResults.results);
      if(Object.keys(vitalSignsResults.results).length !== 0){
      let currentDate = new Date();
      
  
      const sytolic =  vitalSignsResults?.results?.bloodPressure?.value?.systolic || 0;
      const diastolic =  vitalSignsResults?.results?.bloodPressure?.value?.diastolic || 0;
     

    async function scanData(){
      const payload = {
        "source": "Facial Scan",
        "resource": "VITALS",
        "record": {
          "vitals_record": {
            "heart_rate": vitalSignsResults?.results?.heartRate?.value,
            "oxygen_saturation": vitalSignsResults?.results?.oxygenSaturation?.value,
            "breathing_rate": vitalSignsResults?.results?.breathingRate?.value,
            "sdnn": vitalSignsResults?.results?.sdnn?.value,
            "stress_level": vitalSignsResults?.results?.stressLevel?.value,
            "stress_index": vitalSignsResults?.results?.stressIndex?.value,
            "hemoglobin": vitalSignsResults?.results?.hemoglobin?.value,
            "hemoglobin_a1c": vitalSignsResults?.results?.hemoglobinA1c?.value,
            "systolic": sytolic,
            "diastolic": diastolic,
            "rri":vitalSignsResults?.results?.rri?.value,
            "sd1":vitalSignsResults?.results?.sd1?.value,
            "sd2":vitalSignsResults?.results?.sd2?.value,
            "prq":vitalSignsResults?.results?.prq?.value,
            "pns_index":vitalSignsResults?.results?.pnsIndex?.value,
            "pns_zone":vitalSignsResults?.results?.pnsZone?.value,
            "sns_index":vitalSignsResults?.results?.snsIndex?.value,
            "sns_zone":vitalSignsResults?.results?.snsZone?.value,
            "wellness_level":vitalSignsResults?.results?.wellnessLevel?.value,
            "wellness_index":vitalSignsResults?.results?.wellnessIndex?.value,
            "lfhf":vitalSignsResults?.results?.lfhf?.value,
            "date": currentDate
          }
        },
        "status": "CREATED"
      }
     
      await saveScanData(payload).then((res:any)=>{
        if(res.data.code === 2027){
          setapierrormsg(undefined);
          setapisuccessmsg(res.data.message);
          // window.close();
        }
      },(error:any) =>{
         console.log(error);
         setapisuccessmsg(undefined);
         setapierrormsg(error.response.data.message)
      })
     

     }
     scanData();
    }
    else{
      setfacialstop(true);
    }
     
  }, []);

  const onError = (errorData: AlertData) => {
    setError(errorData);
  };

  const onWarning = (warningData: AlertData) => {
    if (
      warningData.code ===
      HealthMonitorCodes.MEASUREMENT_CODE_MISDETECTION_DURATION_EXCEEDS_LIMIT_WARNING
    ) {
      setVitalSigns(null);
    }
    if (
      warningData.code ===
      HealthMonitorCodes.MEASUREMENT_CODE_UNSUPPORTED_ORIENTATION_WARNING
    ) {
      setInfo({
        message: `Warning: ${warningData.code}`,
        type: InfoType.INSTRUCTION,
      });
    } else {
      setWarning(warningData);
    }
  };

  const onStateChange = useCallback((state: SessionState) => {
   if(state === 2){
    setfacialstop(false);
   }
    setSessionState(state);
    if (state === SessionState.MEASURING) {
      setVitalSigns(null);
    }
  }, []);

  const onEnabledVitalSigns = useCallback((vitalSigns: EnabledVitalSigns) => {
    setEnabledVitalSigns(vitalSigns);
  }, []);

  const onOfflineMeasurement = useCallback(
    (offlineMeasurements: OfflineMeasurements) => {
      setOfflineMeasurements(offlineMeasurements);
    },
    [],
  );

  const onActivation = useCallback((activationId: string) => {
    // the device has been activated with activationId
  }, []);

  const onImageData = useCallback((imageValidity: ImageValidity) => {
    let message: string;
    if (imageValidity != ImageValidity.VALID) {
      switch (imageValidity) {
        case ImageValidity.INVALID_DEVICE_ORIENTATION:
          message = 'Unsupported Orientation';
          break;
        case ImageValidity.TILTED_HEAD:
          message = 'Head Tilted';
          break;
        case ImageValidity.FACE_TOO_FAR: // Placeholder, currently not supported
          message = 'You Are Too Far';
          break;
        case ImageValidity.UNEVEN_LIGHT:
          message = 'Uneven Lighting';
          break;
        case ImageValidity.INVALID_ROI:
        default:
          message = 'Face Not Detected';
      }
      setInfo({
        type: InfoType.INSTRUCTION,
        message: message,
      });
    } else {
      setInfoWithDismiss({ type: InfoType.NONE });
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await monitor.initialize({
          licenseKey,
          licenseInfo: {
            onEnabledVitalSigns,
            onOfflineMeasurement,
            onActivation,
          },
        });
        console.log(`Initialized monitor`);
        setIsMonitorReady(true);
        setError({ code: -1 });
      } catch (e) {
       // console.error('Error initializing HealthMonitor', e);
        //setIsMonitorReady(false);
       // setError({ code: e.errorCode });
      }
    })();
  }, [licenseKey, productId]);

  useEffect(() => {
    (async () => {
      try {
        if (!isMonitorReady || !processingTime || !video.current) {
          return;
        }

        sessionState === SessionState.ACTIVE && session.terminate();

        const options: FaceSessionOptions = {
          input: video.current,
          cameraDeviceId: cameraId,
          processingTime,
          onVitalSign,
          onFinalResults,
          onError,
          onWarning,
          onStateChange,
          orientation: DeviceOrientation.PORTRAIT,
          onImageData,
          /*******************************************************************************
           * For accurate vital signs calculation the user's parameters should be provided.
           * The following is an example of how the parameters are passed to the SDK:
           *
           * subjectDemographic: {age: 35, weight: 75, gender: Gender.MALE}
           *
           * When measuring a new user then a new session must be created using the
           * new user's parameters
           * The parameters are used locally on the device only for the vital sign
           * calculation, and deleted when the session is terminated.
           *******************************************************************************/
        };

        const faceSession = await monitor.createFaceSession(options);
        console.log(`Session created`);
        console.log("component done");
        setCameraOn(true);
        setSession(faceSession);
        setError({ code: -1 });
      } catch (e) {
        setError({ code: e.errorCode });
        console.error('Error creating a session', e);
      }
    })();
  }, [processingTime, isMonitorReady]);

  useEffect(() => {
    if (startMeasuring) {
      if (sessionState === SessionState.ACTIVE) {
        session.start();
        setError({ code: -1 });
      }
    } else {
      sessionState === SessionState.MEASURING && session.stop();
    }
  }, [startMeasuring]);
  
  return {
    sessionState,
    vitalSigns: {
      heartRate: {
        value: vitalSigns?.heartRate?.value,
        isEnabled: enabledVitalSigns?.isEnabledHeartRate,
      },
      breathingRate: {
        value: vitalSigns?.breathingRate?.value,
        isEnabled: enabledVitalSigns?.isEnabledBreathingRate,
      },
      stress: {
        value: vitalSigns?.stressLevel?.value,
        isEnabled: enabledVitalSigns?.isEnabledStressLevel,
      },
      hrvSdnn: {
        value: vitalSigns?.sdnn?.value,
        isEnabled: enabledVitalSigns?.isEnabledSdnn,
      },
      spo2: {
        value: null, //TODO Spo2 is currently disabled by algo
        isEnabled: false, //enabledVitalSigns?.isEnabledSpo2,
      },
      bloodPressure: {
        value: vitalSigns?.bloodPressure?.value,
        isEnabled: enabledVitalSigns?.isEnabledBloodPressure,
      },
      prq: {
        value: vitalSigns?.prq?.value,
        isEnabled: enabledVitalSigns?.isEnabledPrq,
      },
      bloodPressureD: {
        value: vitalSigns?.bloodPressure?.value?.diastolic,
        isEnabled: enabledVitalSigns?.isEnabledBloodPressure,
      },
      bloodPressureS: {
        value: vitalSigns?.breathingRate?.value?.systolic,
        isEnabled: enabledVitalSigns?.isEnabledBloodPressure,
      },
      hemoglobin: {
        value: vitalSigns?.hemoglobin?.value,
        isEnabled: enabledVitalSigns?.isEnabledHemoglobin,
      },
      hemoglobinA1c: {
        value: vitalSigns?.hemoglobinA1c?.value,
        isEnabled: enabledVitalSigns?.isEnabledHemoglobinA1c,
      },
    },
    
    offlineMeasurements,
    error,
    warning,
    info,
    isCameraOn,
    apierrormsg,
    apisuccessmsg,
    facialstop,
  };
};


export default useMonitor;
