import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  isMobile,
  isTablet,
  isIos,
  HealthMonitorCodes,
  SessionState,
} from '@binah/web-sdk';
import { useMediaPredicate } from 'react-media-hook';
import {
  useError,
  useLicenseKey,
  useMeasurementDuration,
  useMonitor,
  usePageVisibility,
  usePrevious,
  useWarning,
} from '../hooks';
import Stats from './Stats';
import StartButton from './StartButton';
import { mirror } from '../style/mirror';
import { Flex } from './shared/Flex';
import Timer from './Timer';
import media from '../style/media';
import InfoBar from './InfoBar';
import { ErrorAlert, InfoAlert, WarningAlert } from './alert';
import Loader from './Loader';
import { VideoReadyState } from '../types';
import TopBar from './TopBar';
import Mask from '../assets/mask.svg';
import { getLicenseKey } from './common-service';
import Spinner from './Spinner';
import ApiErrorAlert from './alert/apiErrorAlert';
import ApiSuccessAlert from './alert/apiSuccessAlert';


const MonitorWrapper = styled(Flex)<{ isSettingsOpen: boolean }>`
  flex-direction: column;
  width: 100%;
  height: auto;
  overflow: hidden;
  justify-content: start;
  align-items: center;
  flex: 1;
  z-index: ${({ isSettingsOpen }) => isSettingsOpen && '-1'};
  ${media.tablet`
    width: fit-content;
    justify-content: center;
  `}
`;

const MeasurementContentWrapper = styled(Flex)<{ isMobile: boolean }>`
  width: auto;
  height: ${({ isMobile }) => isMobile && '100%'};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  ${media.mobile`
    margin: 40px 0 60px 0;
  `}
`;

const VideoAndStatsWrapper = styled(Flex)<{ isMobile: boolean }>`
  position: relative;
  justify-content: center;
  width: 100%;
  height: ${({ isMobile }) => isMobile && '100%'};
  ${media.tablet`
  width: auto;
  height: auto;
  `} ${media.wide`
  width: auto;
  height: auto;
  `};
`;

const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const Img = styled.img<{ isDesktop: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: ${({ isDesktop }) => (isDesktop ? 'contain' : 'cover')};
`;

const Video = styled.video<{ isMobile: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: ${() => (isIos() ? 'unset' : isMobile() ? 'cover' : 'contain')};
  ${mirror}
`;

const ButtonWrapper = styled(Flex)`
  flex: 2;
  z-index: 3;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-top: -30px;
  ${media.mobile`
    margin-top: 50px;
  `}
  ${media.tablet`
  padding: 0;
  height: auto;
  width: auto;
  position: absolute;
  right: 0;
  bottom: 42%;
  margin-right: 60px;
`}
`;

const ButtomTimerWrapper = styled(Flex)`
  display: none;
  ${media.tablet`
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    height: 30px;
    display: flex;
  `}
`;

const InfoBarWrapper = styled.div`
  height: 25px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  ${media.mobile`
    flex: 0.45;
  `}
`;

const BinahMonitor = ({
  showMonitor,
  cameraId,
  onLicenseStatus,
  onSettingsClick,
  isSettingsOpen,
}) => {
  if (!showMonitor) {
    return null;
  }

  const video = useRef<HTMLVideoElement>(null);
  const [isMeasurementEnabled, setIsMeasurementEnabled] = useState<boolean>(
    false,
  );
  const [startMeasuring, setStartMeasuring] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingTimeoutPromise, setLoadingTimeoutPromise] = useState<number>();
  const isPageVisible = usePageVisibility();
  const isMediaTablet = useMediaPredicate('(min-width: 1000px)');
  const [processingTime] = useMeasurementDuration();
  const [licenseKey] = useLicenseKey();
  const [key,setkey] = useState<any>(undefined);
  const [duration,setduration] = useState<any>(undefined);
  const [isAPIerror,setAPIerror] = useState<boolean>(false);
  const [errormessage,seterrormessage] = useState<any>();
  const [successmessage,setsuccessmessage] = useState<any>();
  const [hideStartButton,sethideStartButton] = useState<any>(true);
  const {
    sessionState,
    vitalSigns,
    offlineMeasurements,
    error,
    warning,
    info,
    isCameraOn,
    apierrormsg,
    apisuccessmsg,
    facialstop
  } = useMonitor(
    video,
    cameraId,
    duration,
    key,
    null,
    startMeasuring,
    isAPIerror,
  );
  const prevSessionState = usePrevious(sessionState);
  const errorMessage = useError(error);
  const warningMessage = useWarning(warning);

  const isMeasuring = useCallback(
    () => sessionState === SessionState.MEASURING,
    [sessionState],
  );

  useEffect(()=>{
    console.log(facialstop);
   if(facialstop){
    setAPIerror(true);
    seterrormessage("Facial scan is not completed you can restart it to complete the process")
   }
   else{
    setAPIerror(false);
   }
  },[facialstop])

  useEffect(()=>{
   if(apierrormsg !== undefined){
    setAPIerror(true);
    //sethideStartButton(true)
    seterrormessage(apierrormsg)
   }
  },[apierrormsg])

  useEffect(()=>{
   if(apisuccessmsg !== undefined){
     setsuccessmessage(apisuccessmsg);
     sethideStartButton(false);
     setTimeout(() => {
      //window.close()
     }, 4000);
   }
  },[apisuccessmsg])


  const isVideoReady = useCallback(
    () => video.current?.readyState === VideoReadyState.HAVE_ENOUGH_DATA,
    [],
  );

  useEffect(()=>{
   async function getkey(){
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    // Get the value of the 'param' query parameter
    const paramValue = urlParams.get('param');
    localStorage.setItem('key', paramValue)
    // Get the value of the 'message' query parameter
    const messageValue = urlParams.get('message');
    localStorage.setItem('auth', messageValue)

    const locationValue = urlParams.get('location');
    localStorage.setItem('location', locationValue)

    await getLicenseKey().then((res:any)=>{
      setAPIerror(false);
      if(res.status === 200){
           setkey(res.data.body.license_key);
           setduration(res.data.body.measurement_duration);
           localStorage.setItem("simpl_id",res.data.body.simpl_id)
           console.log(localStorage.setItem("simpl_id",res.data.body.simpl_id))
         }
    },(error)=>{
      console.log(error);
      setAPIerror(true);
      seterrormessage(error.response.data.message);
    });
   }
   getkey();
  },[])
  const handleButtonClick = useCallback(() => {
    setIsLoading(true);
    if (sessionState === SessionState.ACTIVE) {
      setStartMeasuring(true);
      console.log(isMeasuring)
      setLoadingTimeoutPromise(
        window.setTimeout(() => setIsLoading(true), processingTime * 1000),
      );
    } else if (isMeasuring()) {
      clearTimeout(loadingTimeoutPromise);
      setStartMeasuring(false);
      console.log(isMeasuring)
    }
  }, [sessionState, setIsLoading, processingTime]);

  useEffect(() => {

    if (isMeasuring()) {
      setIsLoading(false);
      if (errorMessage) {
        setIsMeasurementEnabled(false);
      } else {
        setIsMeasurementEnabled(true);
      }
      !isPageVisible && setStartMeasuring(false);
    } else if (
      (sessionState === SessionState.ACTIVE ||
        sessionState === SessionState.TERMINATED) &&
      errorMessage
    ) {
      setIsMeasurementEnabled(false);
    }
    if (
      sessionState === SessionState.ACTIVE &&
      prevSessionState !== sessionState
    ) {
      setStartMeasuring(false);
      setIsLoading(false);
    }
  }, [errorMessage, sessionState, isPageVisible]);

  useEffect(() => {
    onLicenseStatus(!(error?.code in HealthMonitorCodes));
  }, [error]);

  const mobile = useMemo(() => isMobile(), []);
  const desktop = useMemo(() => !isTablet() && !isMobile(), []);
  // console.log(vitalSigns?.heartRate?.value)
  // console.log(vitalSigns?.breathingRate?.value)
  // console.log(vitalSigns?.stress?.value)
  return (
    <>
    {successmessage !== undefined ? <ApiSuccessAlert message={successmessage}/> : ''}
    {isAPIerror ? <ApiErrorAlert message={errormessage} /> : ''}
      <MonitorWrapper isSettingsOpen={isSettingsOpen}>
        <MeasurementContentWrapper isMobile={mobile}>
          <InfoBarWrapper>
            {/* <InfoBar
              showTimer={isMeasurementEnabled && !isMediaTablet}
              isMeasuring={isMeasuring()}
              durationSeconds={processingTime}
              offlineMeasurements={offlineMeasurements}
            /> */}
          </InfoBarWrapper>
          {!isCameraOn ? <Spinner/> : ''}
          <VideoAndStatsWrapper isMobile={mobile}>
            <VideoWrapper>
              <Img src={Mask} isDesktop={desktop} />
              <Video
                ref={video}
                id="video"
                muted={true}
                playsInline={true}
                isMobile={isMobile()}
              />
            </VideoWrapper>
            {(isMeasuring()
              ? !errorMessage && !warningMessage
              : !errorMessage) &&
              isMeasurementEnabled && <Stats vitalSigns={vitalSigns} />}
            <ErrorAlert message={errorMessage} />
            {isMeasuring() && <WarningAlert message={warningMessage} />}
            {isMeasuring() && <InfoAlert message={info.message} />}
            {!isVideoReady() && licenseKey && <Loader />}
          </VideoAndStatsWrapper>
        {isCameraOn ? <>
                  <ButtomTimerWrapper>
                    {isMeasurementEnabled && (
                      <Timer started={isMeasuring()} durationSeconds={processingTime} />
                    )}
                  </ButtomTimerWrapper>
                {hideStartButton === true && (<ButtonWrapper>
                    <StartButton
                      isLoading={isLoading}
                      isMeasuring={isMeasuring()}
                      onClick={handleButtonClick}
                    />
                  </ButtonWrapper>) }  </> : ''   }
        </MeasurementContentWrapper>
      </MonitorWrapper>
    </>
  );
};

export default BinahMonitor;
